import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Flex from './flex';
import Link from './link';

const CaseStudyCard = ({
  image,
  project,
  type,
  link,
}) => {
  const Wrapper = link ? Link : React.Fragment;
  const wrapperProps = link ? {
    to: link,
    textDecoration: 'none',
    color: 'inherit',
  } : {};
  return (
    <Flex
      flexDirection="column"
      m={2}
    >
      <Wrapper {...wrapperProps}>
        <Flex
          width="276px"
          mb={3}
        >
          <Img fluid={image.childImageSharp.fluid} style={{ width: '100%', }} />
        </Flex>
      </Wrapper>
      <Wrapper {...wrapperProps}>
        <h4 style={{ marginBottom: '20px', }}>{project}</h4>
      </Wrapper>
      <h2>{type}</h2>
    </Flex>
  );
};

CaseStudyCard.propTypes = {
  image: PropTypes.object,
};

export default CaseStudyCard;
